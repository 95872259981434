<template>
  <div class="formula">
    <h1>公式设置</h1>
    <el-form :inline="true">
      <el-form-item label="公式使用场景">
        <el-select v-model="searchParam.model" filterable @change="commitFindList()" style="width: 120px;">
          <el-option v-for="formulaModelItem in show.formulaModelList" :key="formulaModelItem.model" :label="formulaModelItem.name" :value="formulaModelItem.model" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="add()">新建</el-button>
          <el-button type="primary" :disabled="!(show.selected.length == 1)" @click="copy()">复制</el-button>
          <el-button type="warning" :disabled="!(show.selected.length == 1)" @click="update()">详情</el-button>
          <el-button type="danger" :disabled="!(show.selected.length >= 1)" @click="del()">删除</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.formulaList" border height="calc(100vh - 310px)" @selection-change="selectionChange()" ref="tabRef">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column width="55" align="center">
        <template #default="scope">
          <el-link type="primary" @click="update(scope.row)">详情</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="公式名称" />
    </el-table>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'

const router = useRouter()
const tabRef = ref()

const data = reactive({
  formulaList: []
})

const show = reactive({
  formulaModelList: [],
  selected: [],
})

const searchParam = reactive({
  model: null
})
const beforeSearchParam = cache.getObject(cache.keys.pageParam + 'formula')
if (beforeSearchParam) {
  Object.assign(searchParam, beforeSearchParam)
  cache.setObject(cache.keys.pageParam + 'formula', null)
}

api.get('/backend/formula/getModel').then(res => {
  show.formulaModelList = res.list
})

const commitFindList = () => {
  if (!searchParam.model) {
    ElMessage.error('请先选择公式场景')
    return
  }
  data.formulaList = []
  api.get('/backend/formula/get', {
    params: {
      model: searchParam.model
    }
  }).then(res => {
    data.formulaList = res.list
  })
}
if (searchParam.model) {
  commitFindList()
}

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows()
}

const add = () => {
  cache.setObject(cache.keys.pageParam + 'formula', searchParam)
  cache.setObject(cache.keys.pageParam + 'formulaDetail', null)
  router.push('/formulaDetail')
}

const copy = () => {
  cache.setObject(cache.keys.pageParam + 'formula', searchParam)
  const formula = JSON.parse(JSON.stringify(tabRef.value.getSelectionRows()[0]))
  formula.id = null
  formula.operateUser = null
  formula.operateDatetime = null
  cache.setObject(cache.keys.pageParam + 'formulaDetail', formula)
  router.push('/formulaDetail')
}

const update = (row) => {
  cache.setObject(cache.keys.pageParam + 'formula', searchParam)
  const formula = row ? row : tabRef.value.getSelectionRows()[0]
  cache.setObject(cache.keys.pageParam + 'formulaDetail', formula)
  router.push('/formulaDetail')
}

const del = () => {
  const ids = tabRef.value.getSelectionRows().map(formula => formula.id)
  ElMessageBox.confirm('', {
    title: '确认删除',
    message: '将要删除选中的 ' + ids.length + ' 项。',
    autofocus: false,
    confirmButtonText: '确认',
    cancelButtonText: '取消'
  }).then(() => {
    api.post('/backend/formula/del', { ids: ids }).then(() => {
      ElMessage.success('删除成功')
      commitFindList()
    })
  })
}
</script>

<style lang="less"></style>